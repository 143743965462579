import jwt_decode from "jwt-decode";
import { addAuthorizations } from "./asset-keycloak.slice";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import {
  ITokenDetails,
  IUserAuthorizations,
  IUserTokenDetails,
  IValidateTokenPayload,
} from "./asset-keycloak.interface";
import {
  ASSET_SUITE_LOGIN_URL,
  ASSET_SCOPES,
  ASSET_SUITE_SCOPE,
  CLEAR_CLIENT_SCOPES,
  KEYCLOAK_CLIENTS,
  SESSION_KEY,
  SESSION_TYPE,
  IFRAME_ID,
  ASSET_MODELS_SCOPE,
  ASSET_URLS,
  FIELD_SERVICE_MANAGEMENT,
  DIRECT_DASHBOARD_ACCESS_USERS,
  MESSAGES,
  COOKIE_DETAILS,
} from "../../config/default.config";
import { store } from "../../store/index";
import { toasterUtils } from "../../utils/toaster.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { cookieUtils } from "../../utils/cookie.utils";
import { setTheme } from "../seqr-ops/seqr-ops.slice";

export interface IPostDomain {
  frameId: string;
  url: string;
}

// export const passDomainKeyToDashboard = (domain: string)=>{

//   //passing domain in direct login
//   let postMsg = {
//    type: SESSION_TYPE.DOMAIN_PASS_TYPE,
//    domain: encryptUtils.encryptURL(domain)
//  };

//  const iframe = document.getElementById(
//    IFRAME_ID.DASHBOARD_IFRAME_ID
//    ) as HTMLIFrameElement;
//  iframe.contentWindow?.postMessage(postMsg, `${process.env.REACT_APP_DASHBOARD_URL}`);

// }

const processToken = async (
  IValidateTokenPayload: IValidateTokenPayload,
  ITokenDetails: ITokenDetails
) => {
  let cookieName = COOKIE_DETAILS.COOKIE_NAME;
  let domainName = COOKIE_DETAILS.COOKIE_DOMAIN;
  let auth_token = IValidateTokenPayload.token;

  let decoded: any = jwt_decode(auth_token);
  let resources = Object.keys(decoded.resource_access);
  let themeType = decoded["app-theme"];
  let localThemeVar: boolean = false;

  if (themeType === "LIGHT") {
    localThemeVar = true;
  } else {
    localThemeVar = false;
  }

  if (!resources.includes(ASSET_SUITE_SCOPE)) {
    toasterUtils.showError(MESSAGES.USER_UNAUTHORIZED);
  }

  let userAuthorizations: IUserAuthorizations = {
    asset_management: false,
    asset_models: false,
    device_management: false,
    asset_suite: false,
    field_service_management: false,
    operational_dashboard: false,
    management_dashboard: false,
    asset_contract: false,
    monitoring_dashboard: false,
  };

  for (let scope of resources) {
    if (ASSET_SCOPES.includes(scope)) {
      store.dispatch(addAuthorizations(scope));
      switch (scope) {
        case KEYCLOAK_CLIENTS.ASSET_SUITE:
          userAuthorizations = { ...userAuthorizations, asset_suite: true };
          break;
        case KEYCLOAK_CLIENTS.ASSET_MANAGEMENT:
          userAuthorizations = {
            ...userAuthorizations,
            asset_management: true,
          };
          break;
        case KEYCLOAK_CLIENTS.ASSET_MODELS:
          userAuthorizations = { ...userAuthorizations, asset_models: true };
          break;
        case KEYCLOAK_CLIENTS.DEVICE_MANAGEMENT:
          userAuthorizations = {
            ...userAuthorizations,
            device_management: true,
          };
          break;
        case KEYCLOAK_CLIENTS.FIELD_SERVICE_MANAGEMENT:
          userAuthorizations = {
            ...userAuthorizations,
            field_service_management: true,
          };
          break;
        case KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD:
          userAuthorizations = {
            ...userAuthorizations,
            operational_dashboard: true,
          };
          break;
        case KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD:
          userAuthorizations = {
            ...userAuthorizations,
            management_dashboard: true,
          };
          break;
        case KEYCLOAK_CLIENTS.MONITORING_DASHBOARD:
          userAuthorizations = {
            ...userAuthorizations,
            monitoring_dashboard: true,
          };
          break;
        case KEYCLOAK_CLIENTS.ASSET_CONTRACT:
          userAuthorizations = { ...userAuthorizations, asset_contract: true };
          break;
        case CLEAR_CLIENT_SCOPES:
          let IUserAuthorizations: IUserAuthorizations = {
            asset_suite: false,
            asset_management: false,
            asset_models: false,
            device_management: false,
            field_service_management: false,
            operational_dashboard: false,
            management_dashboard: false,
            asset_contract: false,
            monitoring_dashboard: false,
          };
          userAuthorizations = IUserAuthorizations;
          break;
        default:
          toasterUtils.showError("Unrecognized resource access : " + scope);
          break;
      }
    }
  }

  let userTokenDetails: IUserTokenDetails = {
    auth_token: ITokenDetails.auth_token,
    refresh_token: ITokenDetails.refresh_token,
    username: ITokenDetails.username,
    password: ITokenDetails.password,
    client_id: ITokenDetails.client_id,
    client_secret: ITokenDetails.client_secret,
    session_id: ITokenDetails.session_id,
    userAuthorizations: userAuthorizations,
    isLoggedIn: true,
    sourceDomain: ASSET_SUITE_LOGIN_URL,
    themeType: themeType,
  };

  //storing in localstorage
  const data = { token: userTokenDetails };
  console.log("userToken ::", data?.token?.session_id);

  sessionStorageUtils.setLocalStorage("ut", data);

  let sessionId = data?.token?.session_id;
  let sessionThemeType = {
    sessionId: sessionId,
    themeType: themeType,
  };
  let encryptedSessionThemeType = encryptUtils.encryptURL(sessionThemeType);
  store.dispatch(setTheme(localThemeVar));
  cookieUtils.setCookie(cookieName, encryptedSessionThemeType, {
    domain: domainName,
  });

  //DIRECT LOGIN LOGIC
  // if(encryptUtils.decryptURL(localStorage.getItem(SESSION_KEY.REDIRECT_LOCATION_KEY)) !== null){
  //   let target = encryptUtils.decryptURL(localStorage.getItem(SESSION_KEY.REDIRECT_LOCATION_KEY));

  //   switch (target) {
  //       case SESSION_KEY.MODELS_KEY:
  //         if(userAuthorizations.asset_models){
  //           window.location.href=ASSET_URLS.ASSET_MODELS_URL
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }else{
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }
  //         break;
  //       case SESSION_KEY.ASSETS_KEY:
  //         if(userAuthorizations.asset_management){
  //           window.location.href=ASSET_URLS.ASSET_MANAGEMENT_URL
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }else{
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }
  //         break;
  //       case SESSION_KEY.DEVICE_MANAGEMENET_KEY:
  //         if(userAuthorizations.device_management){
  //           window.location.href=ASSET_URLS.DEVICE_MANAGEMENT_URL
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }else{
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }
  //         break;
  //       case SESSION_KEY.FSM_KEY:
  //         if(userAuthorizations.field_service_management){
  //           window.location.href=ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }else{
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }
  //         break;
  //       case SESSION_KEY.OPERATIONAL_DASHBOARD_KEY:
  //         if(userAuthorizations.operational_dashboard){

  //           passDomainKeyToDashboard(KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD);
  //           window.location.href=ASSET_URLS.OPERATIONAL_DASHBOARD_URL
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }else{
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }
  //         break;
  //       case SESSION_KEY.MANAGEMENT_DASHBOARD_KEY:
  //         if(userAuthorizations.management_dashboard){

  //           passDomainKeyToDashboard(KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD);
  //           window.location.href=ASSET_URLS.MANAGEMENT_DASHBOARD_URL
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }else{
  //           localStorage.removeItem(SESSION_KEY.REDIRECT_LOCATION_KEY);
  //         }
  //         break;
  //       default:
  //           window.location.href=ASSET_URLS.APP_SUITE_URL
  //       }
  // }

  //SETTING TOKEN
  // for (let scope of resources) {
  //   if (ASSET_SCOPES.includes(scope)) {
  //     store.dispatch(addAuthorizations(scope));
  //   switch (scope) {
  //       case KEYCLOAK_CLIENTS.ASSET_SUITE:
  //         break;
  //       case KEYCLOAK_CLIENTS.ASSET_MANAGEMENT:
  //         postCrossDomainMessage(data, SESSION_TYPE.USER_LOGIN_TYPE, KEYCLOAK_CLIENTS.ASSET_MANAGEMENT);
  //         break;
  //     case KEYCLOAK_CLIENTS.ASSET_MODELS:
  //         postCrossDomainMessage(data, SESSION_TYPE.USER_LOGIN_TYPE, KEYCLOAK_CLIENTS.ASSET_MODELS);
  //         break;
  //     case KEYCLOAK_CLIENTS.DEVICE_MANAGEMENT:
  //         postCrossDomainMessage(data, SESSION_TYPE.USER_LOGIN_TYPE, KEYCLOAK_CLIENTS.DEVICE_MANAGEMENT);
  //         break;
  //     case KEYCLOAK_CLIENTS.FIELD_SERVICE_MANAGEMENT:
  //         postCrossDomainMessage(data, SESSION_TYPE.USER_LOGIN_TYPE, KEYCLOAK_CLIENTS.FIELD_SERVICE_MANAGEMENT);
  //         break;
  //     case KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD:
  //         postCrossDomainMessage(data, SESSION_TYPE.USER_LOGIN_TYPE, KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD);
  //         break;
  //     case KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD:
  //         postCrossDomainMessage(data, SESSION_TYPE.USER_LOGIN_TYPE, KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD);
  //         break;
  //     case CLEAR_CLIENT_SCOPES:
  //         let IUserAuthorizations: IUserAuthorizations = {
  //           asset_suite : false,
  //           asset_management : false,
  //           asset_models : false,
  //           device_management : false,
  //           field_service_management : false,
  //           operational_dashboard : false,
  //           management_dashboard : false,
  //         }
  //        userAuthorizations = IUserAuthorizations;
  //         break;
  //       default:
  //         toasterUtils.showError('Unrecognized resource access : ' + scope);
  //         break;
  //       }
  //     }
  //   };
};
//POSTING TOKEN VIA POST MESSAGE
// export const postCrossDomainMessage = (tokenDetails: any, type: any, scope: string) => {

//   let ut = encryptUtils.encryptURL(tokenDetails);
//   let postMsg = { [SESSION_KEY.LOCAL_STORAGE_KEY] : ut, type: type };

//    const POST_DOMAIN_DATA_FOR_CLEAR_TOKEN_DATA = [
//     {
//       frameId: IFRAME_ID.MODELS_IFRAME_ID,
//       url: `${process.env.REACT_APP_MODELS_URL}`,
//     }, {
//       frameId: IFRAME_ID.ASSETS_IFRAME_ID,
//       url: `${process.env.REACT_APP_ASSETS_URL}`,
//     }, {
//       frameId: IFRAME_ID.DEVICE_MANAGEMENET_IFRAME_ID,
//       url: `${process.env.REACT_APP_DEVICE_MANAGEMENT_URL}`,
//     }, {
//       frameId: IFRAME_ID.FSM_IFRAME_ID,
//       url: `${process.env.REACT_APP_FSM_URL}`,
//     }
//     , {
//       frameId: IFRAME_ID.DASHBOARD_IFRAME_ID,
//       url: `${process.env.REACT_APP_DASHBOARD_URL}`,
//     }
//     , {
//       frameId: IFRAME_ID.DASHBOARD_IFRAME_ID,
//       url: `${process.env.REACT_APP_DASHBOARD_URL}`,
//     },
//   ];

//   let POST_DOMAIN: IPostDomain ={
//     frameId: "",
//     url: ""
//   };

//   switch (scope) {
//     case KEYCLOAK_CLIENTS.ASSET_MODELS:
//       POST_DOMAIN =  {
//             frameId: IFRAME_ID.MODELS_IFRAME_ID,
//             url: `${process.env.REACT_APP_MODELS_URL}`,
//           };
//       break;
//     case KEYCLOAK_CLIENTS.ASSET_MANAGEMENT:
//       POST_DOMAIN = {
//         frameId: IFRAME_ID.ASSETS_IFRAME_ID,
//         url: `${process.env.REACT_APP_ASSETS_URL}`,
//       };
//       break;
//     case KEYCLOAK_CLIENTS.FIELD_SERVICE_MANAGEMENT:
//       POST_DOMAIN = {
//         frameId: IFRAME_ID.FSM_IFRAME_ID,
//         url: `${process.env.REACT_APP_FSM_URL}`,
//       };
//       break;
//     case KEYCLOAK_CLIENTS.DEVICE_MANAGEMENT :
//       POST_DOMAIN = {
//         frameId: IFRAME_ID.DEVICE_MANAGEMENET_IFRAME_ID,
//         url: `${process.env.REACT_APP_DEVICE_MANAGEMENT_URL}`,
//       };
//       break;
//     case KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD :
//       POST_DOMAIN = {
//         frameId: IFRAME_ID.DASHBOARD_IFRAME_ID,
//         url: `${process.env.REACT_APP_DASHBOARD_URL}`,
//       };
//       break;
//     case KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD :
//       POST_DOMAIN = {
//         frameId: IFRAME_ID.DASHBOARD_IFRAME_ID,
//         url: `${process.env.REACT_APP_DASHBOARD_URL}`,
//       };
//       break;
//     case CLEAR_CLIENT_SCOPES:
//       for (let i = 0; i < POST_DOMAIN_DATA_FOR_CLEAR_TOKEN_DATA.length; i++) {
//         const iframe = document.getElementById(
//           POST_DOMAIN_DATA_FOR_CLEAR_TOKEN_DATA[i].frameId
//           ) as HTMLIFrameElement;
//         iframe.contentWindow?.postMessage(postMsg, POST_DOMAIN_DATA_FOR_CLEAR_TOKEN_DATA[i].url);
//       }
//       return;
//     default:
//       toasterUtils.showError('Unrecognized resource access : ' + scope);
//       break;
//     }

//       const iframe = document.getElementById(
//         POST_DOMAIN.frameId
//         ) as HTMLIFrameElement;
//       iframe.contentWindow?.postMessage(postMsg, POST_DOMAIN.url);
//   }

export const TokenServices = {
  processToken,
};
