import React, { useEffect, useState } from "react";
import jwt_decode from "jwt-decode";
import "./dashboard.scss";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  ASSET_REDIRECT_SCOPES,
  ASSET_SUITE_LOGIN_URL,
  ASSET_URLS,
  COOKIE_DETAILS,
  DASHBOARD_TYPE,
  IFRAME_ID,
  KEYCLOAK_CLIENTS,
  SESSION_KEY,
  SESSION_TYPE,
} from "../../config/default.config";
import assetModelsIcon from "../../assets/images/asset_model_icon.svg";
import assetManagementIcon from "../../assets/images/asset_management_icon.svg";
import adminConsoleIcon from "../../assets/images/device_management_icon.svg";
import managementDashboardIcon from "../../assets/images/management_dashboard_icon.svg";
import serverRoomMonitoringDashboard from "../../assets/images/Server-Room-Monitoring-Dashboard-icon.svg";
import operationalDashboardIcon from "../../assets/images/operational_dashboard.svg";
import fsmIcon from "../../assets/images/fsm_icon.svg";
import {
  IUserAuthorizations,
  IUserTokenDetails,
} from "../../store/keycloak/asset-keycloak.interface";
import Navbar from "../../components/navbar.component/navbar";
import { setTokens } from "../../store/keycloak/asset-keycloak.slice";
import { sessionStorageUtils } from "../../utils/session-storage.utils";
import { encryptUtils } from "../../utils/encryption.utils";
import { cookieUtils } from "../../utils/cookie.utils";
import settingsIcon from "../../assets/images/seqrops-settings_icon.svg";
import seqropsSiteManagement from "../../assets/images/site-management-icon.svg";

import { setTheme } from "../../store/seqr-ops/seqr-ops.slice";

function Dashboard() {
  const authorozedScopes = useAppSelector((state) => state.assetKeycloak);
  const dispatch = useAppDispatch();
  const [loggedUser, setLoggedUser] = useState<string>();
  const [userScopesFromLocalStorage, setUserScopesFromLocalStorage] =
    useState<IUserAuthorizations>();
  const [ready, setReady] = useState<boolean>(false);
  const [zoomedTile, setZoomedTile] = useState<string>("");
  const urlParams = new URLSearchParams(window.location.search);

  const handleMouseEnter = (tile: string) => {
    setZoomedTile(tile);
  };

  const handleMouseLeave = () => {
    setZoomedTile("");
  };

  // const handleClick = (e: any) => {
  //   // e.preventDefault();
  //   // dispatch(seqrOpsActions.setLoader(true));
  //   window.location.href = ASSET_URLS.ASSET_MODELS_URL;
  // };

  useEffect(() => {
    let cookieName = COOKIE_DETAILS.COOKIE_NAME;
    let sessionId = cookieUtils.getCookie(cookieName);

    let decryptedSessionThemeType = encryptUtils.decryptURL(sessionId);

    if (
      decryptedSessionThemeType?.sessionId == null ||
      decryptedSessionThemeType?.sessionId === undefined ||
      decryptedSessionThemeType?.sessionId === ""
    ) {
      localStorage.clear();
      window.location.replace(ASSET_SUITE_LOGIN_URL);
    } else {
      if (
        sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY) !==
        null
      ) {
        let cookieName = COOKIE_DETAILS.COOKIE_NAME;
        let encryptedCookieData = cookieUtils.getCookie(cookieName);
        let decryptedCookieData = encryptUtils.decryptURL(encryptedCookieData);
        if (decryptedCookieData?.themeType === "LIGHT") {
          dispatch(setTheme(false));
        } else if (decryptedCookieData?.themeType === "DARK") {
          dispatch(setTheme(true));
        }

        let userTokenDetails: IUserTokenDetails =
          sessionStorageUtils.getLocalStorage(
            SESSION_KEY.LOCAL_STORAGE_KEY
          )?.token;
        if (userTokenDetails !== null) {
          setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
          setLoggedUser(userTokenDetails?.username);
          dispatch(setTokens(userTokenDetails));

          const newArray = [];
          let data: any = userTokenDetails?.userAuthorizations;
          const trueKeys = Object.keys(data).filter((key) => data[key]);
          trueKeys.forEach((key) => {
            newArray.push(key);
          });
          if (
            trueKeys.length === 5 &&
            trueKeys.includes(ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD) &&
            urlParams.get(DASHBOARD_TYPE.REDIRECT) == null
          ) {
            let dashboardURL = ASSET_URLS.OPERATIONAL_DASHBOARD_URL;
            const queryParams = new URLSearchParams({
              dashboardType: ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD,
            });
            const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
            window.location.href = urlWithParams;
          }
          if (
            trueKeys.length === 3 &&
            trueKeys.includes(ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD) &&
            urlParams.get(DASHBOARD_TYPE.REDIRECT) == null
          ) {
            let dashboardURL = ASSET_URLS.OPERATIONAL_DASHBOARD_URL;
            const queryParams = new URLSearchParams({
              dashboardType: ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD,
            });
            const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
            window.location.href = urlWithParams;
          } else if (
            trueKeys.length === 3 &&
            trueKeys.includes(ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD) &&
            urlParams.get(DASHBOARD_TYPE.REDIRECT) == null
          ) {
            let dashboardURL = ASSET_URLS.MANAGEMENT_DASHBOARD_URL;
            const queryParams = new URLSearchParams({
              dashboardType: ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD,
            });
            const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
            window.location.href = urlWithParams;
          } else if (trueKeys.length === 1) {
            let target = trueKeys[0];
            switch (target) {
              case ASSET_REDIRECT_SCOPES.ASSET_MODELS:
                window.location.href = ASSET_URLS.ASSET_MODELS_URL;
                break;
              case ASSET_REDIRECT_SCOPES.ASSET_MANAGEMENT:
                window.location.href = ASSET_URLS.ASSET_MANAGEMENT_URL;
                break;
              case ASSET_REDIRECT_SCOPES.DEVICE_MANAGEMENT:
                window.location.href = ASSET_URLS.DEVICE_MANAGEMENT_URL;
                break;
              case ASSET_REDIRECT_SCOPES.FIELD_SERVICE_MANAGEMENT:
                window.location.href = ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL;
                break;
              case ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD:
                window.location.href = ASSET_URLS.OPERATIONAL_DASHBOARD_URL;
                break;
              case ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD:
                window.location.href = ASSET_URLS.MANAGEMENT_DASHBOARD_URL;
                break;
              case ASSET_REDIRECT_SCOPES.MONITORING_DASHBOARD:
                window.location.href = ASSET_URLS.MONITORING_DASHBOARD_URL;
                break;
              default:
                window.location.href = ASSET_URLS.APP_SUITE_URL;
            }
          } else {
            setReady(true);
          }
        }
      } else {
        window.location.href = ASSET_SUITE_LOGIN_URL;
      }
    }
  }, []);

  const renderAssetModels = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "assetModels" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("assetModels")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          window.open(ASSET_URLS.ASSET_MODELS_URL, "_self");
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section">
          <div className="seqrops-tile-inner-section">
            <img
              src={assetModelsIcon}
              className="img-fluid"
              alt="asset-models-img"
            />
            <span>
              Asset <br /> Models
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderAssetManagement = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "assetManagement" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("assetManagement")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          window.open(ASSET_URLS.ASSET_MANAGEMENT_URL, "_self");
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section">
          <div className="seqrops-tile-inner-section">
            <img
              src={assetManagementIcon}
              className="img-fluid"
              alt="asset-management-img"
            />
            <span>
              Asset <br /> Management
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderDeviceManagement = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "deviceManagement" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("deviceManagement")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          window.open(ASSET_URLS.DEVICE_MANAGEMENT_URL, "_self");
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section">
          <div className="seqrops-tile-inner-section">
            <img
              src={adminConsoleIcon}
              className="img-fluid"
              alt="admin-console-img"
            />
            <span>
              Device <br /> Management
            </span>
          </div>
        </div>
      </div>
    );
  };
  const [dashboardTypee, setDashboardTypee] = useState<string | null>(null);
  const [assetUrl, setAssetUrl] = useState<string | undefined>(undefined);
  const [siteUrl, setSiteUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    let userTokenDetails: IUserTokenDetails =
      sessionStorageUtils.getLocalStorage(SESSION_KEY.LOCAL_STORAGE_KEY)?.token;
    if (userTokenDetails !== null) {
      setUserScopesFromLocalStorage(userTokenDetails?.userAuthorizations);
      setLoggedUser(userTokenDetails?.username);
      dispatch(setTokens(userTokenDetails));
      const newArray = [];
      let data: any = userTokenDetails?.userAuthorizations;
      const trueKeys = Object.keys(data).filter((key) => data[key]);
      trueKeys.forEach((key) => {
        newArray.push(key);
      });
      const urlParams = new URLSearchParams(window.location.search);
      const dashboardType = urlParams.get("redirectFrom");
      setDashboardTypee(dashboardType);
      if (dashboardTypee === ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD) {
        if (
          trueKeys.length === 4 &&
          trueKeys.includes(ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD)
        ) {
          setSiteUrl(
            ASSET_URLS.CONTRACT_URL +
              "/?" +
              DASHBOARD_TYPE.REDIRECT +
              "=" +
              dashboardTypee
          );
        }
        if (
          trueKeys.length === 3 &&
          trueKeys.includes(ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD)
        ) {
          setAssetUrl(
            ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL +
              "/?" +
              DASHBOARD_TYPE.REDIRECT +
              "=" +
              dashboardTypee
          );
        }
      } else if (
        dashboardTypee === ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD
      ) {
        if (
          trueKeys.length === 4 &&
          trueKeys.includes(ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD)
        ) {
          setSiteUrl(
            ASSET_URLS.CONTRACT_URL +
              "/?" +
              DASHBOARD_TYPE.REDIRECT +
              "=" +
              dashboardTypee
          );
        }
        if (
          trueKeys.length === 3 &&
          trueKeys.includes(ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD)
        ) {
          setAssetUrl(
            ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL +
              "/?" +
              DASHBOARD_TYPE.REDIRECT +
              "=" +
              dashboardTypee
          );
        }
      } else {
        setAssetUrl(ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL);
        setSiteUrl(ASSET_URLS.CONTRACT_URL);
      }
    }
  }, [dashboardTypee]);

  const renderFieldServiceManagement = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "fieldServiceManagement" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("fieldServiceManagement")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          // window.open(ASSET_URLS.FIELD_SERVICE_MANAGEMENT_URL, "_self");
          window.open(assetUrl, "_self");
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section1">
          <div className="seqrops-tile-inner-section">
            <img src={fsmIcon} className="img-fluid" alt="FSM-img" />
            <span>
              Field Service <br /> Management
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderManagementDashboard = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "managementDashboard" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("managementDashboard")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          handleDashboardClick(ASSET_REDIRECT_SCOPES.MANAGEMENT_DASHBOARD);
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section1">
          <div className="seqrops-tile-inner-section">
            <img
              src={managementDashboardIcon}
              className="img-fluid"
              alt="efficency-dsh-img"
            />
            <span>
              Management <br /> Dashboard
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderOperationalDashboard = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "operationalDashboard" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("operationalDashboard")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          handleDashboardClick(ASSET_REDIRECT_SCOPES.OPERATIONAL_DASHBOARD);
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section1">
          <div className="seqrops-tile-inner-section">
            <img
              src={operationalDashboardIcon}
              className="img-fluid"
              alt="operation-dsh-img"
            />
            <span>
              Operations <br /> Dashboard
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderServiceRoomMonitoringDashboard = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "serviceRoomMonitoringDashboard" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("serviceRoomMonitoringDashboard")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          handleDashboardClick(ASSET_REDIRECT_SCOPES.MONITORING_DASHBOARD);
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section1">
          <div className="seqrops-tile-inner-section">
            <img
              src={serverRoomMonitoringDashboard}
              className="img-fluid"
              alt="operation-dsh-img"
            />
            <span>
              Monitoring
              <br /> Dashboard
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderConworx = () => {
    return (
      <div
        className={`seqrops-dashboard-tile-wrapper ${
          zoomedTile === "conworxDashboard" ? "zoom-effect" : ""
        }`}
        onMouseEnter={() => handleMouseEnter("conworxDashboard")}
        onMouseLeave={handleMouseLeave}
        onClick={() => {
          window.open(siteUrl, "_self");
        }}
      >
        <div className="seqrops-dashboard-tile-middle-section1">
          <div className="seqrops-tile-inner-section">
            <img
              src={seqropsSiteManagement}
              className="img-fluid"
              alt="operation-dsh-img"
            />
            <span>Site Management</span>
          </div>
        </div>
      </div>
    );
  };

  const handleDashboardClick = (domain: string) => {
    let dashboardURL = ASSET_URLS.OPERATIONAL_DASHBOARD_URL;

    if (domain === KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD) {
      dashboardURL = ASSET_URLS.MANAGEMENT_DASHBOARD_URL;
    }
    const queryParams = new URLSearchParams({ dashboardType: domain });
    const urlWithParams = `${dashboardURL}?${queryParams.toString()}`;
    window.open(urlWithParams, "_self");
  };

  if (!ready) {
    return null;
  }

  return (
    <>
      {ready && (
        <>
          <div className="seqrops-dashboard-wrapper">
            <div className="seqrops-navbar-section">
              <Navbar
                loggeduser={
                  userScopesFromLocalStorage !== undefined
                    ? loggedUser
                    : authorozedScopes.username
                }
              />
            </div>
            <div className="container mt-5">
              <div className="tile-wrapper-main flex-wrap">
                <React.Fragment>
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.asset_models
                    : authorozedScopes.userAuthorizations.asset_models) &&
                    renderAssetModels()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.asset_management
                    : authorozedScopes.userAuthorizations.asset_management) &&
                    renderAssetManagement()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.device_management
                    : authorozedScopes.userAuthorizations.device_management) &&
                    renderDeviceManagement()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.field_service_management
                    : authorozedScopes.userAuthorizations
                        .field_service_management) &&
                    renderFieldServiceManagement()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.operational_dashboard
                    : authorozedScopes.userAuthorizations
                        .operational_dashboard) && renderOperationalDashboard()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.management_dashboard
                    : authorozedScopes.userAuthorizations
                        .management_dashboard) && renderManagementDashboard()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.monitoring_dashboard
                    : authorozedScopes.userAuthorizations
                        .monitoring_dashboard) &&
                    renderServiceRoomMonitoringDashboard()}
                  {(userScopesFromLocalStorage !== undefined
                    ? userScopesFromLocalStorage.asset_contract
                    : authorozedScopes.userAuthorizations.asset_contract) &&
                    renderConworx()}
                </React.Fragment>

                {/* //ux user case next if */}
                {/* {authorozedScopes.username === "ux user" && 
                    <>
                        {renderAssetManagement() }    
                        {renderAssetModels() }    
                        {renderDeviceManagement() }   
                        {renderFieldServiceManagement() }
                        {renderManagementDashboard() }
                        {renderOperationalDashboard() }
                    </>} */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default Dashboard;
