import {
  CLEAR_CLIENT_SCOPES,
  KEYCLOAK_CLIENTS,
} from "../../config/default.config";
import { toasterUtils } from "../../utils/toaster.utils";
import {
  IKeycloakSlice,
  ITokenDetails,
  IUserAuthorizations,
} from "./asset-keycloak.interface";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

//initial state
const initialState: IKeycloakSlice = {
  auth_token: "",
  refresh_token: "",
  username: "",
  password: "",
  client_id: "",
  client_secret: "",
  session_id: "",
  userAuthorizations: {
    asset_management: false,
    asset_models: false,
    device_management: false,
    asset_suite: false,
    field_service_management: false,
    operational_dashboard: false,
    management_dashboard: false,
    asset_contract: false,
    monitoring_dashboard: false,
  },
};

const assetKeycloakSlice = createSlice({
  name: "assetKeycloak",
  initialState: initialState,
  reducers: {
    setTokens: (state, action: PayloadAction<ITokenDetails>) => {
      state.auth_token = action.payload.auth_token;
      state.refresh_token = action.payload.refresh_token;
      state.client_id = action.payload.client_id;
      state.client_secret = action.payload.client_secret;
      state.username = action.payload.username;
      state.password = action.payload.password;
      state.session_id = action.payload.session_id;
    },
    addAuthorizations: (state, action: PayloadAction<any>) => {
      let scope = action.payload;

      switch (scope) {
        case KEYCLOAK_CLIENTS.ASSET_SUITE:
          state.userAuthorizations = {
            ...state.userAuthorizations,
            asset_suite: true,
          };
          break;
        case KEYCLOAK_CLIENTS.ASSET_MANAGEMENT:
          state.userAuthorizations = {
            ...state.userAuthorizations,
            asset_management: true,
          };
          break;
        case KEYCLOAK_CLIENTS.ASSET_MODELS:
          state.userAuthorizations = {
            ...state.userAuthorizations,
            asset_models: true,
          };
          break;
        case KEYCLOAK_CLIENTS.DEVICE_MANAGEMENT:
          state.userAuthorizations = {
            ...state.userAuthorizations,
            device_management: true,
          };
          break;
        case KEYCLOAK_CLIENTS.FIELD_SERVICE_MANAGEMENT:
          state.userAuthorizations = {
            ...state.userAuthorizations,
            field_service_management: true,
          };
          break;
        case KEYCLOAK_CLIENTS.OPERATIONAL_DASHBOARD:
          state.userAuthorizations = {
            ...state.userAuthorizations,
            operational_dashboard: true,
          };
          break;
        case KEYCLOAK_CLIENTS.MANAGEMENT_DASHBOARD:
          state.userAuthorizations = {
            ...state.userAuthorizations,
            management_dashboard: true,
          };
          break;
        case KEYCLOAK_CLIENTS.ASSET_CONTRACT:
          state.userAuthorizations = {
            ...state.userAuthorizations,
            asset_contract: true,
          };
          break;
        case KEYCLOAK_CLIENTS.MONITORING_DASHBOARD:
          state.userAuthorizations = {
            ...state.userAuthorizations,
            monitoring_dashboard: true,
          };
          break;
        case CLEAR_CLIENT_SCOPES:
          let IUserAuthorizations: IUserAuthorizations = {
            asset_suite: false,
            asset_management: false,
            asset_models: false,
            device_management: false,
            field_service_management: false,
            operational_dashboard: false,
            management_dashboard: false,
            asset_contract: false,
            monitoring_dashboard: false,
          };
          state.userAuthorizations = IUserAuthorizations;
          break;
        default:
          toasterUtils.showError("Unrecognized resource access : " + scope);
          break;
      }
    },
  },
});

export const { setTokens, addAuthorizations } = assetKeycloakSlice.actions;
export default assetKeycloakSlice.reducer;
